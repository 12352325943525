import type { ILayoutModuleOptions, ItemModel, DataItemModel, RecordSourceOptions } from 'o365-dataobject';
import type { PropertiesItemModel } from './DataObject.PropertyItem.ts'

import { API } from 'o365-modules';
import { DataObject, Item as DataItem, LayoutModule, getDataObjectById, getOrCreateDataObject } from 'o365-dataobject';
import { EventEmitter, configurableRegister } from 'o365-modules';
import { BulkOperation, logger } from 'o365-utils';
import { getPropertiesItemModel } from './DataObject.PropertyItem.ts'

declare module 'o365-dataobject' {
    interface DataObject<T extends ItemModel = ItemModel> {
        propertiesData: PropertiesData<T>;
        hasPropertiesData: boolean;
    }
}

Object.defineProperties(DataObject.prototype, {
    'propertiesData': {
        get() {
            if (this._propertiesData == null) {
                this._propertiesData = new PropertiesData(this);
                this.hasPropertiesData = true;
            }
            return this._propertiesData;
        }
    },
});

/** DataObject extension for extending data items with properties from a sub-table  */
export default class PropertiesData<T extends ItemModel = ItemModel>{
    private _dataObject: DataObject<T>;
    private _propertiesDataObject!: DataObject;
    private _initialized = false;
    private _initializationPromise?: Promise<boolean>;
    private _updated: Date = new Date();
    private _enabled = false;
    /** Disable layout tracking for properties */
    disableTracking = false;

    private _viewName!: string;
    private _uniqueTableName!: string;
    private _configView!: string;
    private _itemIdField!: string;
    private _propertyField!: string;
    private _propertyIdField!: string;
    private _propertiesDefinitionProc?: string;
    private _distinctPropertiesView?: string;
    private _distinctPropertiesExistsClauseBindings: Record<string, string> = {};
    private _useTableForRetrieve = false;

    private _propertiesUniqueKey?: string;

    private _selectedProperties: string[] = [];
    private _propertiesDefinitions: Record<string, PropertiesDefintion> = {};

    private _bulkRetrieveDebounce: number | null = null;
    private _bulkRetrievePromise: Promise<void> | null = null;
    private _bulkRetrieveProperties: ({ ID: string | number, res: (data: any[]) => void, rej: (ex: unknown) => void })[] | null = null;

    private _getPropertyDefintion = new BulkOperation<number, PropertyModel>({
        bulkOperation: async (pIds) => {
            const definitions = await getPropertiesDefinitions(pIds.map(x => x.value));
            pIds.forEach((item) => {
                const definition = definitions.find(x => x.ID === item.value);
                if (definition) {
                    item.res(definition);
                } else {
                    item.rej(new TypeError(`Could not retrieve property definition with ID ${item.value}`));
                }
            });
        }
    });

    private _refreshAfterUpdate?: boolean;
    private _cancelBeforeSave?: () => void;
    private _cancelAfterSave?: () => void;
    /** After save event on properties dataobject */
    private _cancelAfterSave2?: () => void;
    private _cleanupTokens: (() => void)[] = [];
    private _propertiesItemModel: ReturnType<typeof getPropertiesItemModel<T>>;
    events: EventEmitter<PropertiesDataEvents, {
        'PropertyRemovedAsync': (pProperty: string, pOptions: { modules: string[] }) => Promise<void>
    }>;

    /** Optional where clause that will be used when retrieving properties values */
    propertiesWhereClause?: string;

    /**
     * When enabled will refresh property values after the main row is updated (using an AfterSave event)
     * By default this is turned off.
     */
    get refreshAfterUpdate() { return this._refreshAfterUpdate; }
    set refreshAfterUpdate(value) {
        this._refreshAfterUpdate = value;
        if (this._cancelAfterSave) { this._cancelAfterSave(); }
        if (this._cancelAfterSave2) { this._cancelAfterSave2(); }
        if (this._refreshAfterUpdate) {
            this._cancelAfterSave = this._dataObject.on('AfterSave', (_pOptions, _pRow, pItem) => {
                if (pItem instanceof this.PropertiesItemModel) {
                    pItem.resetProperties();
                    pItem.isPropertiesLoading;
                }
            });
            if (this._propertiesDataObject) {
                this._cancelAfterSave2 = this._propertiesDataObject.on('AfterSave', (_pOptions, _pRow, pItem) => {
                    const item = this._getMasterItem(pItem);
                    if (item && item instanceof this.PropertiesItemModel) {
                        item.resetProperties();
                        item.isPropertiesLoading;
                    }
                });
            }
        } else {
            this._cancelAfterSave = undefined;
            this._cancelAfterSave2 = undefined;
        }
    }

    limitSelectableProperties?: boolean;

    /** Only works with client side handlers (NodeData full structure) */
    enablePropertiesSorting = false;

    /** The value of PropertyViewName from sviw_System_PropertiesViews for this DataObject's view */
    get viewName() { return this._viewName; }
    /** The value of PropertyUniqueTableName from sviw_System_PropertiesViews for this DataObject's unique table name */
    get uniqueTableName() { return this._uniqueTableName; }
    /** View name to wich properties are connected. Usualy will be dataObject.viewName but can be overriden */
    get configView() { return this._configView; }

    /** The value of PropertyBinding.Master from sviw_System_PropertiesViews for this DataObject's view */
    get itemIdField() { return this._itemIdField; }
    /** The value of PropertyBinding.Detail from sviw_System_PropertiesViews for this DataObject's view */
    get propertyIdField() { return this._propertyIdField; }
    /**
     * Should always be 'PropertyName'
     * The field from PropertyViewName which is used to join in properties from stbv_System_Properties
     */
    get propertyField() { return this._propertyField; }
    /** Array of selected properties names */
    get selectedProperties() { return this._selectedProperties; }
    /** A dictionary of selected properties and their definitions */
    get propertiesDefinitions() { return this._propertiesDefinitions; }

    /** Date at which display data was last updated. Used by watchers */
    get updated() { return this._updated; }
    /** Indicates if the node data overrides are currently enabled */
    get enabled() { return this._enabled; }
    /** Promise that is resolved when the extension has finished initialization */
    get initializationPromise() { return this._initializationPromise; }
    /** Internal properties DataObject with the view from PropertyViewName  */
    get propertiesDataObject() { return this._propertiesDataObject; }
    /** Unique key for internal properties DataObject fields. (In case your view doesn't have PrimKey) */
    get propertiesUniqueKey() {
        return this._propertiesUniqueKey;
    }
    set propertiesUniqueKey(pValue: string | undefined) {
        this._propertiesUniqueKey = pValue;
        if (this._propertiesDataObject) {
            this._propertiesDataObject.fields.uniqueField = pValue;
            if (pValue) {
                this._propertiesDataObject.fields.addField({ name: pValue })
            }
        }
    }

    /** Definition proc for properties view */
    get propertyDefintionProc() { return this._propertiesDefinitionProc; }
    set propertyDefintionProc(pProc) { this._propertiesDefinitionProc = pProc; }

    /** Overriden view to be used in properties dataobject */
    get distinctPropertiesView() { return this._distinctPropertiesView; }
    set distinctPropertiesView(pView) { this._distinctPropertiesView = pView; }

    get distinctPropertiesExistsClauseBindings() { return this._distinctPropertiesExistsClauseBindings; }

    get useTableViewForRetrieve() { return this._useTableForRetrieve; }
    set useTableViewForRetrieve(pValue) { this._useTableForRetrieve = pValue; }

    get changedDate() {
        return this._propertiesDataObject.state.changedDate
    }

    get PropertiesItemModel() { return this._propertiesItemModel; }

    /** Indicates that properties definitions are being loaded in */
    applyingProperties = false;

    constructor(pDataObject: DataObject<T>) {
        this._dataObject = pDataObject;
        this.events = new EventEmitter();

        this._propertiesItemModel = getPropertiesItemModel(this._dataObject.storage.DataItemModel);
    }

    // async initialize(pOptions: NonNullable<DataObjectOptions['propertiesDefinition']>) {
    async initialize(pOptions?: {
        viewName?: string;
        bindingField?: string;
    }) {
        if (this._initialized) { return; }
        this._initialized = true;
        let promiseRes = (_pSuccess: boolean) => { };
        this._initializationPromise = new Promise((res, rej) => {
            promiseRes = res;
        });
        this._configView = pOptions?.viewName ?? this._dataObject.viewName;
        const config = await getViewConfiguration(this._configView);
        if (config == null) {
            this._dataObject.hasPropertiesData = false;
            promiseRes(false);
            return;
        }

        this._viewName = config.PropertyViewName;
        this._uniqueTableName = config.PropertyUniqueTableName;
        this._propertyIdField = config.PropertyBinding.split('=')[0].trim();
        this._itemIdField = pOptions?.bindingField ?? config.PropertyBinding.split('=')[1].trim();
        // this._propertyIdField = pOptions.detailField;
        // this._itemIdField = pOptions.masterField;
        // this._propertyField = pOptions.propertyField ?? 'PropertyName';
        this._propertyField = 'PropertyName';
        const fields = [
            { name: this.propertyIdField },
            { name: this.propertyField },
        ];
        this._propertiesDataObject = getOrCreateDataObject({
            id: `o_${this._dataObject.id}_properties`,
            appId: this._dataObject.appId,
            // viewName: this._viewName,
            viewName: this.useTableViewForRetrieve
                ? this._viewName
                : this._distinctPropertiesView ?? this._viewName,
            uniqueTable: this._uniqueTableName,
            definitionProc: this._propertiesDefinitionProc,
            allowInsert: this._dataObject.allowUpdate || this._dataObject.allowInsert,
            allowUpdate: this._dataObject.allowUpdate || this._dataObject.allowInsert,
            allowDelete: this._dataObject.allowUpdate || this._dataObject.allowInsert,
            disableSaveOncurrentIndexChange: true,
            fields: fields,
            disableLayouts: true,
        });
        this._propertiesDataObject.createNewAtTheEnd = true;
        // this._propertiesDataObject.storage.DataItemModel.?
        if (this._propertiesUniqueKey) {
            this._propertiesDataObject.fields.uniqueField = this._propertiesUniqueKey;
            this._propertiesDataObject.fields.addField({ name: this._propertiesUniqueKey });
        }

        if (this._dataObject.layoutManager) {
            this._dataObject.layoutManager.registerModule('properties', PropertiesLayoutModule);
        }

        this.enable();
        promiseRes(true);
    }

    /** Enable PropertiesData overrides on the DataObject */
    enable() {
        if (this._enabled) { return; }
        this._enabled = true;

        this._dataObject.storage.setDataItemConstructor(this._createExtendedItem.bind(this));
        this._updateStorageItems();
        if (this._dataObject.hasDynamicLoading) {
            this._dataObject.dynamicLoading.dataLoaded(this._dataObject.storage.data, { skip: 0 });
        }

        if (this._refreshAfterUpdate && this._cancelAfterSave == undefined) {
            this._cancelAfterSave = this._cancelAfterSave = this._dataObject.on('AfterSave', (_pOptions, _pRow, pItem) => {
                if (pItem instanceof this.PropertiesItemModel) {
                    pItem.resetProperties();
                    pItem.isPropertiesLoading;
                }
            });
        }
        if (this._refreshAfterUpdate && this._cancelAfterSave2 == undefined) {
            this._cancelAfterSave2 = this._propertiesDataObject.on('AfterSave', (_pOptions, _pRow, pItem) => {
                const item = this._getMasterItem(pItem);
                if (item && item instanceof this.PropertiesItemModel) {
                    item.resetProperties();
                    item.isPropertiesLoading;
                }
            });
        }

        if (this.useTableViewForRetrieve && this._propertiesDefinitionProc) {
            this._cleanupTokens.push(this._propertiesDataObject.on('BeforeSave', (pOptions) => {
                if (pOptions.definitionProc) {
                    pOptions.definitionProc = undefined;
                    pOptions.definitionProcParameters = undefined;
                    pOptions.sqlStatementParameters = undefined;
                }
            }));
        }

        this._cancelBeforeSave = this._dataObject.on('BeforeSave', (pOptions, _v, pItem) => {
            if (pItem instanceof this.PropertiesItemModel) {
                const saveChanges = () => {
                    pItem.propertiesRowsArray?.forEach(property => {
                        if (property.hasChanges) {
                            property.save();
                        }
                    });
                };
                if (pOptions.eventPromise) {
                    pOptions.eventPromise.then(() => {
                        if (!pOptions.cancelEvent) {
                            saveChanges();
                        }
                    });
                } else {
                    saveChanges();
                }
            }
        });
    }


    /** Disable PropertiesData overrides on the DataObject */
    disable() {
        if (!this._enabled) { return; }
        this._enabled = false;

        this._dataObject.storage.setDataItemConstructor(null);
        if (this._cancelAfterSave) {
            this._cancelAfterSave();
            this._cancelAfterSave = undefined;
        }
        if (this._cancelAfterSave2) {
            this._cancelAfterSave2();
            this._cancelAfterSave2 = undefined;
        }
        if (this._cancelBeforeSave) {
            this._cancelBeforeSave();
            this._cancelBeforeSave = undefined;
        }
        if (this._cleanupTokens.length) {
            this._cleanupTokens.splice(0, this._cleanupTokens.length).forEach(ct => ct());
        }
    }

    /**
     * Register an exists clause binding for distinct properties list. This will execute the exists clause on the properties values instead, 
     * unregistered exists clauses are removed from distinct propreties retrieve request.
     * 
     * @param pViewName Viewname used in the exists clause
     * @param pBinding Binding of the exists clause to the properites values where T2 is from `pViewName` and T1 is from properties values view 
     */
    registerDistinctExistClause(pViewName: string, pBinding: string) {
        this._distinctPropertiesExistsClauseBindings[pViewName] = pBinding;
    }

    /**
     * Set selected properties from properties definitions  
     * @param pProperties Array of properties definitions to set as selected
     */
    setProperties(pProperties: PropertyModel[]) {
        for (const property of this._selectedProperties) {
            if (pProperties.findIndex(x => x.Name === property) === -1) {
                this.events.emit('PropertyRemoved', property);
            }
        }
        this._selectedProperties = [];
        this._propertiesDefinitions = {};
        return Promise.all(pProperties.map((property) => {
            return this.addProperty(property, false);
        }));
    }

    /**
     * Set selected properties by ids and names. Will retrieve the properties definitions and set them as selected. 
     * Previous selected properties will be unselected.
     * @param pProperties Array of objects containing ID and Name from `sviw_System_PropertiesWithEditors`
     */
    async setPropertiesByIds(pProperties: { ID: number; Name: string }[]) {
        if (this._getPropertyDefintion.bulkPromise) {
            await this._getPropertyDefintion.bulkPromise;
        }
        this._selectedProperties.forEach(property => {
            if (pProperties.findIndex(x => x.Name === property) === -1) {
                this.events.emit('PropertyRemoved', property);
            }
        });

        this.applyingProperties = true;
        this._selectedProperties = [];
        this._propertiesDefinitions = {};
        const promises = pProperties.map(property => {
            return this.addPropertyById(property.ID, false);
        });
        await Promise.all(promises);
        this.applyingProperties = false;
    }

    /**
     * Add property to the selceted list by id. WIll retrieve the definition before selecting  
     * @param pId Id of system property to select
     * @param pTrackChange Track this property seleciton in the layout
     */
    async addPropertyById(pId: number, pTrackChange = true) {
        const definition = await this._getPropertyDefintion.addToQueue(pId);
        if (definition == null) { return; }
        this.addProperty(definition, pTrackChange);
    }

    /**
     * Add property to the selceted list by definition. 
     * @param pProperty Property definition from `sviw_System_PropertiesWithEditors`
     * @param pTrackChange Track this property seleciton in the layout
     */
    async addProperty(pProperty: PropertyModel, pTrackChange = true) {
        if (this._initializationPromise) {
            await this._initializationPromise;
        }
        const fieldName = `Property.${pProperty.Name}`;
        let valueField: PropertiesDefintion['valueField'] = 'Value';
        switch (pProperty.DataType) {
            case 'bool':
            case 'number':
            case 'numeric':
                valueField = 'IntValue'
                break;
            case 'decimal':
                valueField = 'DecimalValue'
                break;
            case 'date':
                valueField = 'DateValue';
                break;
            case 'datetime':
                valueField = 'DateTimeValue';
                break;
            default:
                valueField = 'Value';
        }
        if (!this._dataObject.fields.fieldExists(fieldName) && !this._dataObject.fields.fieldExistsInView(fieldName) && this._dataObject.fields[fieldName] == null) {
            const parseType = (pType: string) => {
                switch (pType) {
                    case 'bool':
                        return 'bit';
                    case 'date':
                        return 'date';
                    case 'datetime':
                        return 'datetime';
                    case 'number':
                    case 'numeric':
                    case 'decimal':
                        return 'number';
                    default:
                        return 'string';
                }
            }
            const dataType = parseType(pProperty.DataType);

            const field = this._dataObject.fields.createField({
                name: fieldName,
                type: dataType,
                existsDefinition: {
                    viewName: this._propertiesDataObject.viewName,
                    valueField: valueField,
                    binding: [{
                        detailField: this.propertyIdField,
                        operator: 'equals',
                        masterField: this.itemIdField
                    }],
                }
            });
            if (pProperty.Caption) {
                field.caption = pProperty.Caption;
            }
            this._dataObject.fields.combinedFields.push(field);
        }

        if (this._selectedProperties?.includes(pProperty.Name)) { return; }
        this._selectedProperties.push(pProperty.Name);

        const getDefaultFormat = (pProperty:PropertyModel) =>{
            if(pProperty.Format) return pProperty.Format;
            if(pProperty.DataType == "decimal"){
                return '1 234.12'
            }
            return pProperty.Format;
        }

        this._propertiesDefinitions[pProperty.Name] = {
            id: pProperty.ID,
            name: pProperty.Name,
            valueField: valueField,
            caption: pProperty.Caption,
            dataType: pProperty.DataType,
            hasLookupValues: pProperty.HasLookupValues,
            group: pProperty.Group,
            title: pProperty.Title,
            description: pProperty.Description,
            whereClause: pProperty.WhereClause,
            isUrl: pProperty.IsUrl,
            placeholder: pProperty.Placeholder,
            format:getDefaultFormat(pProperty),
            unit: pProperty.Unit,
            masterProperty: pProperty.MasterProperty,
            masterDetailBinding: pProperty.MasterDetailBinding
        };
        if (pProperty.InputEditor && pProperty.Config) {
            this._propertiesDefinitions[pProperty.Name].inputEditor = {
                ...(JSON.parse(pProperty.Config)),
                Name: pProperty.InputEditor
            };
        }

        const parseDataType = (pType: string) => {
            switch (pType) {
                case 'bool':
                    return 'bit';
                case 'date':
                    return 'date';
                case 'datetime':
                    return 'datetime2';
                case 'number':
                case 'numeric':
                    return 'int';
                default:
                    return 'nvarchar';
            }
        }
        const sqlDataType = parseDataType(pProperty.DataType);

        this._propertiesDataObject.fields.addField({ name: 'Value' });
        this._propertiesDataObject.fields.addField({ name: 'IntValue', dataType: 'int' });
        this._propertiesDataObject.fields.addField({ name: valueField, dataType: sqlDataType });

        this._updateInnerFields();

        for (const item of this._dataObject.storage.data) {
            if (item instanceof this.PropertiesItemModel) {
                item.resetProperties();
            }
        }
        if (pTrackChange) {
            this._trackChanges();
        }
        this.events.emit('PropertyAdded', pProperty.Name, this._propertiesDefinitions[pProperty.Name]);
    }

    /**
     * Remove property from the selected list by name
     * @param pPropertyName Name of property to remove
     * @param pTrackChange Track this removal in layouts
     */
    removeProperty(pPropertyName: string, pTrackChange = true) {
        if (this._propertiesDefinitions[pPropertyName] == null) { return; }
        const propertyIndex = this._selectedProperties.findIndex(x => x === pPropertyName);
        if (propertyIndex !== -1) {
            this._selectedProperties.splice(propertyIndex, 1);
        }
        delete this._propertiesDefinitions[pPropertyName];
        if (pTrackChange) {
            const options = {
                modules: ['properties']
            };
            this.events.emitAsync('PropertyRemovedAsync', pPropertyName, options).finally(() => {
                this._trackChanges(options);
            });
        }
        this.events.emit('PropertyRemoved', pPropertyName);
    }

    /**
     * Get main value field used by a property
     * @param pPropertyName Name of the property to get the value field for
     */
    getValueField(pPropertyName: string) {
        return this._propertiesDefinitions[pPropertyName]?.valueField;
    }

    /**
     * Get or create a DataItem for property by value id.
     * @param pId Binding value from configured values view
     * @param pProperty Property name
     */
    getOrCreatePropertyItem(pId: string | number, pProperty: string) {
        if (this.selectedProperties == null) { return null; }
        const item = this._propertiesDataObject.storage.data.find(x => x[this.propertyIdField] === pId && x[this.propertyField] === pProperty);
        if (item) { return item; }
        const newItem = this._propertiesDataObject.createNew({
            [this.propertyField]: pProperty,
            [this.propertyIdField]: pId
        }, false);
        newItem.defaultValues[this.propertyField] = pProperty;
        newItem.defaultValues[this.propertyIdField] = pId;
        newItem.reset();
        return newItem;
    }

    /**
     * Get existing properties for item
     * @param pId - Binding value from configured values view
     */
    async getPropertiesForItem(pId: string | number) {
        if (pId == null) { return Promise.resolve([]); }
        if (this._bulkRetrievePromise) { await this._bulkRetrievePromise; }
        if (this._bulkRetrieveDebounce) { window.clearTimeout(this._bulkRetrieveDebounce); }
        return new Promise<DataItem[]>((res, rej) => {
            if (this._bulkRetrieveProperties == null) { this._bulkRetrieveProperties = []; }
            this._bulkRetrieveProperties.push({
                ID: pId, res, rej
            });
            this._bulkRetrieveDebounce = window.setTimeout(() => {
                let resolve = () => { };
                this._bulkRetrievePromise = new Promise((res) => { resolve = res; });
                this._doBulkRowRetrieve().then(() => {
                    this._bulkRetrieveProperties = null;
                    this._bulkRetrieveDebounce = null;
                    resolve();
                    this._bulkRetrievePromise = null;
                });
            }, 100);
        });
    }

    /**
     * Get existing properties for item. Unlike `getPropertiesForItem` this will not do a bulk request.
     * Should not be used in cases where multiple simultaneous retrieves are expected like in lists or grids.
     * @param pId - Binding value from the configured values view
     */
    async getPropertiesForSingleItem(pId: string | number) {
        if (pId == null) { return []; }
        if (this._selectedProperties.length === 0) { return []; }

        this._propertiesDataObject.recordSource.whereClause = this._selectedProperties.length > 1
            ? `[${this.propertyField}] IN (${this._selectedProperties.map(field => `'${field}'`).join(',')})`
            : `[${this.propertyField}] ='${this._selectedProperties[0]}'`;
        if (this.propertiesWhereClause) {
            this._propertiesDataObject.recordSource.whereClause = `${this.propertiesWhereClause} AND ${this._propertiesDataObject.recordSource.whereClause}`
        }

        const filterString = `${this.propertyIdField} = '${pId}'`

        let data: DataItemModel[];
        let definitionProc = this.useTableViewForRetrieve ? this._propertiesDataObject.recordSource.definitionProc : undefined;
        try {
            if (definitionProc) { this._propertiesDataObject.recordSource.definitionProc = undefined; }
            if (this._propertiesDataObject.recordSource.definitionProc) {
                this._propertiesDataObject.recordSource.definitionProcParameters = this._dataObject.recordSource.definitionProcParameters;
                this._propertiesDataObject.recordSource.sqlStatementParameters = this._dataObject.recordSource.sqlStatementParameters;
            }
            data = await this._propertiesDataObject.recordSource.refreshRowsByFilter(filterString, false, true) as DataItemModel[];
        } catch (ex) {
            logger.error(ex);
            return [];
        } finally {
            if (definitionProc) {
                this._propertiesDataObject.recordSource.definitionProc = definitionProc;
            }
        }
        
        return data;
    }

    /** Retrieve existing properties names and ids for an item */
    async getExistingPropertiesForItem(pItem: DataItemModel<T>, pLoadAll?: boolean) {
        return getExistingPropertiesForItem(pItem, this._dataObject, pLoadAll);
    }

    /** Add additional fields that should be loaded for the properties data items */
    setAdditionalFields(pFields: string[]) {
        if( !this._propertiesDataObject) return;
        for (const field of pFields) {
            this._propertiesDataObject.fields.addField({ name: field });
        }
        this._updateInnerFields();
    }


    async bulkUpdate(pOptions: {
        bulkItem: {
            [P in PropertiesDefintion['valueField']]: any
        },
        selectedItems: PropertiesItemModel<T>[],
        bulkFields: string[]
    }) {
        if (pOptions.bulkFields.length !== 1) {
            logger.error('Malformed options provided for properties bulk update. Can only update one property at a time');
            return;
        }
        const propertyName = pOptions.bulkFields[0].split('Property.').slice(1).join('');
        const createRows: (string | number)[] = [];
        const updateRows: DataItemModel[] = [];
        const selectedAreKeys = typeof pOptions.selectedItems[0] !== 'object';
        if (selectedAreKeys) {
            if (this.itemIdField != this._dataObject.fields.uniqueField) {
                logger.error('Bulk update on properties though selected keys is only available when your dataObject.uniqueField is the same as the property bound field');
                return;
            }
            const options = this._propertiesDataObject.recordSource.getOptions();

            return this._propertiesDataObject.dataHandler.update({
                ...options,
                key: this.propertyIdField,
                // @ts-ignore
                put: true,
                bulk: true,
                values: {
                    data: pOptions.selectedItems.map(key => {
                        return {
                            ...pOptions.bulkItem,
                            'PropertyName': pOptions.bulkFields[0].split('.')[1],
                            [this.propertyIdField]: key,
                        };
                    })
                },
                whereClause: `[PropertyName] = '${pOptions.bulkFields[0].split('.')[1]}'`
            });
        }

        pOptions.selectedItems.forEach(row => {
            const propertyKey: string | number = (row as any)[this.itemIdField];
            const propertyRow = row.propertiesRows?.[propertyName]
            if (propertyRow && propertyRow.isNewRecord == false) {
                for (let key in pOptions.bulkItem) {
                    propertyRow[key] = (pOptions.bulkItem as any)[key];
                }
                updateRows.push(propertyRow);
            } else {
                createRows.push(propertyKey);
            }
        });

        const promises: Promise<any>[] = [];
        if (createRows.length > 0) {
            promises.push(this._propertiesDataObject.recordSource.bulkCreate(createRows.map(key => {
                return {
                    ...pOptions.bulkItem,
                    [this.propertyField]: propertyName,
                    [this.propertyIdField]: key,
                };
            })));
        }
        if (updateRows.length > 0) {
            promises.push(this._propertiesDataObject.recordSource.bulkSaveItems(updateRows));
        }
        try {
            await Promise.all(promises);
        } catch (ex) {
            return { error: ex };
        }

        pOptions.selectedItems.forEach(row => {
            row.resetProperties();
        });
        return { error: null };
    }

    /** Replace existing DataItems with PropertiesItems. Used when enabling extension on a loaded DataObject */
    private _updateStorageItems() {
        return this._dataObject.storage.data.map((item, index) => {
            if (!(item instanceof this.PropertiesItemModel)) {
                const newItemOptions = this._dataObject.storage.newItemOptionsFactory();
                const propertiesItem = new this.PropertiesItemModel(index, item.item, newItemOptions);
                propertiesItem._initProperties({
                    getPropertiesData: () => this,
                    refreshProperties: this.getPropertiesForItem.bind(this)
                })
                propertiesItem.current = this._dataObject.currentIndex === index;
                this._dataObject.storage.data.splice(index, 1, (propertiesItem as any) as DataItemModel<T>);
            }
        });
    }

    /** Bulk retrieval of properties values implementation */
    private async _doBulkRowRetrieve() {
        if (this._bulkRetrieveProperties == null) { return; }

        if (this._selectedProperties.length === 0) { this._bulkRetrieveProperties.forEach(row => row.res([])); return; }

        this._propertiesDataObject.recordSource.whereClause = this._selectedProperties.length > 1
            ? `[${this.propertyField}] IN (${this._selectedProperties.map(field => `'${field}'`).join(',')})`
            : `[${this.propertyField}] ='${this._selectedProperties[0]}'`;
        if (this.propertiesWhereClause) {
            this._propertiesDataObject.recordSource.whereClause = `${this.propertiesWhereClause} AND ${this._propertiesDataObject.recordSource.whereClause}`
        }

        const filterString = this._bulkRetrieveProperties.length == 1
            ? `${this.propertyIdField} = '${this._bulkRetrieveProperties[0].ID}'`
            : `${this.propertyIdField} IN (${this._bulkRetrieveProperties.map(x => `'${x.ID}'`).join(',')})`;

        let data: DataItemModel[];
        let definitionProc = this.useTableViewForRetrieve ? this._propertiesDataObject.recordSource.definitionProc : undefined;
        try {
            if (definitionProc) { this._propertiesDataObject.recordSource.definitionProc = undefined; }
            if (this._propertiesDataObject.recordSource.definitionProc) {
                this._propertiesDataObject.recordSource.definitionProcParameters = this._dataObject.recordSource.definitionProcParameters;
                this._propertiesDataObject.recordSource.sqlStatementParameters = this._dataObject.recordSource.sqlStatementParameters;
            }
            data = await this._propertiesDataObject.recordSource.refreshRowsByFilter(filterString) as DataItemModel[];
        } catch (ex) {
            this._bulkRetrieveProperties.forEach(row => row.rej(ex));
            return;
        } finally {
            if (definitionProc) {
                this._propertiesDataObject.recordSource.definitionProc = definitionProc;
            }
        }

        this._bulkRetrieveProperties.forEach(row => {
            const rows = data.filter(x => x[this.propertyIdField] === row.ID);
            row.res(rows);
        });
    }

    /** Get the master row for a property DataItem */
    private _getMasterItem(pItem: DataItemModel) {
        let dataItem = this._dataObject.storage.data.find(item => {
            return item[this.itemIdField] == pItem[this.propertyIdField];
        });

        if (dataItem == null && this._dataObject.batchDataEnabled) {
            dataItem = this._dataObject.batchData.data.find(item => {
                return item[this.itemIdField] == pItem[this.propertyIdField];
            });
        }

        return dataItem;
    }

    /** Storage DataItem constructor override */
    private _createExtendedItem(...args: ConstructorParameters<typeof DataItem<T>>) {
        const item = new this.PropertiesItemModel(...args);
        item._initProperties({
            getPropertiesData: () => this,
            refreshProperties: this.getPropertiesForItem.bind(this)
        });
        return item;
    }

    /** Trigger function for saving propertes changes in layouts */
    private _trackChanges(_pOptions?: {
        modules: string[]
    }) {
        if (this._dataObject.layoutManager && !this.disableTracking && this._dataObject.layoutManager.activeLayout) {
            this._dataObject.layoutManager.saveLocalChanges();
        }
    }

    protected _updateInnerFields() {
        if (this._propertiesDataObject.storage.DataItemModel?.updateSetters) {
            this._propertiesDataObject.storage.DataItemModel.updateSetters(this._propertiesDataObject.fields.fields.map(field => field.name));
        }
    }
}

/**
 * Array of fields that are selected from `sviw_System_PropertiesWithEditorsAndRegisters` and `sviw_System_PropertiesWithEditors`
 * 
 * New fields that need to be selected should be added here
 */
const SystemPropertiesFields = [
    'ID', 'Name', 'Title', 'Caption', 'DataType', 'InputEditor',
    'Config', 'HasLookupValues', 'Group', 'Description', 'WhereClause', 'IsInformation',
    'IsUrl', 'Placeholder', 'Format', 'Unit', 'MasterProperty', 'MasterDetailBinding'
] as const;

/** Helper type for mapping system properties fields */
type SystemPropertiesFieldsType = (typeof SystemPropertiesFields)[number];

/** Parsed definition from PropertyModel */
export type PropertiesDefintion = {
    id: number;
    name: string;
    valueField: 'Value' | 'DateValue' | 'DateTimeValue' | 'IntValue' | 'DecimalValue',
    dataType: PropertyModel['DataType'],
    caption?: string;
    title?: string;
    group?: string;
    description?: string;
    inputEditor?: {
        Name: string,
        Type: 'Lookup' | 'DatePicker' | 'Field' | 'OrgUnit' | 'Object' | 'TimePicker';
        ViewName?: string;
        Columns?: string;
        DisplayMember?: string;
        ValueMember?: string;
        SortOrder?: string;
        Multiselect?: boolean;
        RestrictToContext?: boolean;
    };
    hasLookupValues: boolean;
    whereClause?: string;
    isUrl?: boolean;
    placeholder?: string;
    format?: string;
    unit?: string;
    masterProperty?: string;
    masterDetailBinding?: string;
};

export type PropertiesDataEvents = {
    'PropertyAdded': (pProperty: string, pDefinition: PropertiesDefintion) => void;
    'PropertyRemoved': (pProperty: string) => void;
};

// --- Selectable properties helpers ---

/**
 * Helper type for defining property field types. If not present here than the field will have `any`
 */
type PropertyModelDefinedTypes = {
    ID: number;
    Name: string;
    DataType: 'text' | 'number' | 'bool' | 'datetime' | 'date' | 'numeric' | 'string' | 'decimal';
    Title?: string;
    Caption?: string;
    InputEditor?: string;
    Config?: string;
    HasLookupValues: boolean;
    Description?: string;
    Group?: string;
    WhereClause?: string;
    IsInformation?: boolean;
    IsUrl?: boolean;
    Placeholder?: string;
    Format?: string;
    Unit?: string;
    MasterProperty?: string;
    MasterDetailBinding?: string;
};


/** sviw_System_PropertiesWithEditors view model */
type PropertyModel = Omit<{ [key in SystemPropertiesFieldsType]: any }, keyof PropertyModelDefinedTypes> & PropertyModelDefinedTypes;

/** Wrapper class around selectable property record  */
export class SelectableProperty implements PropertyModel {
    ID!: PropertyModel['ID'];
    Name!: PropertyModel['Name'];
    DataType!: PropertyModel['DataType'];
    Title: PropertyModel['Title'];
    Caption: PropertyModel['Caption'];
    InputEditor: PropertyModel['InputEditor'];
    Config: PropertyModel['Config'];
    HasLookupValues!: PropertyModel['HasLookupValues'];
    Description: PropertyModel['Description'];
    Group: PropertyModel['Group'];
    WhereClause: PropertyModel['WhereClause'];
    IsUrl: PropertyModel['IsUrl'];
    IsInformation: PropertyModel['IsInformation'];
    Placeholder: PropertyModel['Placeholder'];
    Format: PropertyModel['Format'];
    Unit: PropertyModel['Unit'];
    MasterProperty: PropertyModel['MasterProperty'];
    MasterDetailBinding: PropertyModel['MasterDetailBinding'];

    SearchValue: string;

    private _onSelected: (pProperty: SelectableProperty) => void;
    private _onUnSelected: (pProperty: SelectableProperty) => void;
    private _isInSelectedList: () => boolean;

    get isSelected() { return this._isInSelectedList(); }
    set isSelected(value) {
        if (value) {
            this._onSelected(this);
        } else {
            this._onUnSelected(this);
        }
    }

    constructor(pItem: PropertyModel, pOptions: {
        onSelected: (pProperty: SelectableProperty) => void,
        onUnSelected: (pProperty: SelectableProperty) => void,
        isInSelectedList: (pName: string) => boolean;
    }) {
        this._onSelected = pOptions.onSelected;
        this._onUnSelected = pOptions.onUnSelected;

        Object.keys(pItem).forEach((key) => {
            (this as any)[key] = pItem[key as keyof PropertyModel];
        });
        this.SearchValue = pItem.Title ?? pItem.Name;

        this._isInSelectedList = () => pOptions.isInSelectedList(pItem.Name);
    }
}

/** Retrieve properties configuration for the given view */
export async function getViewConfiguration(pViewName: string) {
    const requestPath = `PropertiesConfig-${pViewName}`;
    const options: RecordSourceOptions = {
        viewName: 'sviw_System_PropertiesViews',
        fields: [
            { name: 'PropertyViewName' },
            { name: 'PropertyUniqueTableName' },
            { name: 'PropertyBinding' },
        ],
        skip: 0,
        maxRecords: 1,
        whereClause: `[ViewName] = '${pViewName}'`
    };

    if (configurableRegister.isConfigured) {
        options.viewName = 'sviw_System_PropertiesRegisters';
        options.whereClause = `[Register_ID] = ${configurableRegister.id}`;
    }

    const headers = new Headers({
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'X-NT-API': 'true'
    });

    const response = await API.request({
        requestInfo: `/nt/api/data/${requestPath}`,
        method: 'POST',
        headers: headers,
        body: JSON.stringify({
            operation: 'retrieve',
            ...options
        }),
    });

    if (response[0]) {
        return {
            PropertyViewName: response[0].PropertyViewName,
            PropertyUniqueTableName: response[0].PropertyUniqueTableName,
            PropertyBinding: response[0].PropertyBinding,
        };
    } else {
        return undefined;
    }
}


export async function getPropertiesDefinitions(pProperties: number[]) {
    const requestPath = `properties-definitions`;
    const whereClause = pProperties.length > 1
        ? `[ID] IN (${pProperties.map(field => `'${field}'`).join(',')})`
        : `[ID] ='${pProperties[0]}'`

    const options: RecordSourceOptions = {
        viewName: 'sviw_System_PropertiesWithEditors',
        fields: SystemPropertiesFields.map(field => ({ name: field })),
        skip: 0,
        maxRecords: -1,
        whereClause: whereClause
    };

    if (configurableRegister.isConfigured) {
        options.viewName = 'sviw_System_PropertiesWithEditorsAndRegisters';
        options.whereClause += ` AND [Register_ID] = ${configurableRegister.id}`;
    }

    const headers = new Headers({
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'X-NT-API': 'true'
    });

    const response: PropertyModel[] = await API.request({
        requestInfo: `/nt/api/data/${requestPath}`,
        method: 'POST',
        headers: headers,
        body: JSON.stringify({
            operation: 'retrieve',
            ...options
        }),
    });

    return response;
}

export async function getExistingPropertiesForItem<T extends ItemModel = ItemModel>(pItem: DataItemModel<T>, pDataObject?: DataObject<T>, pLoadAll?: boolean) {
    let dataObject = pDataObject;
    if (dataObject == null) {
        dataObject = getDataObjectById(pItem.dataObjectId, pItem.appId);
    }
    if (dataObject == null || !dataObject.hasPropertiesData || pItem == null) { return; }
    dataObject.propertiesData.viewName
    const requestPath = `${dataObject.id}-ExistingProperties`;
    let whereClause = pLoadAll
        ? `exists_clause(sviw_System_PropertiesViews, T2.[PropertyName] = T1.[Name], [ViewName] = '${dataObject.viewName}')`
        : `exists_clause(${dataObject.propertiesData.viewName}, T2.[PropertyName] = T1.[Name], [${dataObject.propertiesData.propertyIdField}] = '${pItem[dataObject.propertiesData.itemIdField]}')`;
    const options: RecordSourceOptions = {
        viewName: 'sviw_System_PropertiesWithEditors',
        fields: SystemPropertiesFields.map(field => ({ name: field })),
        skip: 0,
        maxRecords: -1,
    };

    if (configurableRegister.isConfigured) {
        options.viewName = 'sviw_System_PropertiesWithEditorsAndRegisters';
        whereClause = `[Register_ID] = ${configurableRegister.id}`;
    }
    options.whereClause = whereClause;

    const headers = new Headers({
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'X-NT-API': 'true'
    });

    const response: PropertyModel[] = await API.request({
        requestInfo: `/nt/api/data/${requestPath}`,
        method: 'POST',
        headers: headers,
        body: JSON.stringify({
            operation: 'retrieve',
            ...options
        }),
    });

    return response;
}

export async function getSelectedProperties<T extends ItemModel = ItemModel>(pDataObject: DataObject<T>, pOptions?: {
    onSelected?: (pProperty: SelectableProperty) => void;
    onUnSelected?: (pProperty: SelectableProperty) => void;
}) {
    if (pDataObject.propertiesData == null) { return; }

    const requestPath = `${pDataObject.id}-SelectableProperties`
    const options: RecordSourceOptions = {
        viewName: 'sviw_System_PropertiesWithEditors',
        fields: SystemPropertiesFields.map(field => ({ name: field })),
        skip: 0,
        maxRecords: -1,
    };

    let whereClause = `exists_clause(sviw_System_PropertiesViews, T2.[PropertyName] = T1.[Name], [ViewName] = '${pDataObject.propertiesData.configView}')`;
    if (pDataObject.propertiesData.propertiesWhereClause && pDataObject.propertiesData.limitSelectableProperties) {
        whereClause = `${whereClause} AND exists_clause(${pDataObject.propertiesData.viewName}, T2.[PropertyName] = T1.[Name], ${pDataObject.propertiesData.propertiesWhereClause})`;
    }

    if (configurableRegister.isConfigured) {
        options.viewName = 'sviw_System_PropertiesWithEditorsAndRegisters';
        whereClause = `[Register_ID] = ${configurableRegister.id}`;
    }
    options.whereClause = whereClause;

    const headers = new Headers({
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'X-NT-API': 'true'
    });

    const response = await API.request({
        requestInfo: `/nt/api/data/${requestPath}`,
        method: 'POST',
        headers: headers,
        body: JSON.stringify({
            operation: 'retrieve',
            ...options
        }),
    });

    return response.map((item: any) => new SelectableProperty(item, {
        isInSelectedList: (pName: string) => pDataObject.propertiesData.selectedProperties.includes(pName),
        onSelected: (pProperty) => {
            pDataObject.propertiesData.addProperty(pProperty);
            if (pOptions?.onSelected) {
                pOptions.onSelected(pProperty);
            }
        },
        onUnSelected: (pProperty) => {
            if (pOptions?.onUnSelected) {
                pOptions.onUnSelected(pProperty);
            }
            pDataObject.propertiesData.removeProperty(pProperty.Name);
        },
    }));
}

//--- Layouts implementation ---

// type PropertiesLayout = Record<string, PropertiesDefintion>;
type PropertiesLayout = {
    selected: { Name: string; ID: number }[],
};
class PropertiesLayoutModule<IT extends ItemModel = ItemModel> extends LayoutModule<IT, PropertiesLayout> {
    constructor(pOptions: ILayoutModuleOptions<IT>, pInitialValue: PropertiesLayout | undefined, _pModuleOptions: {},
        _pParentValue?: PropertiesLayout, pStoredChanges?: PropertiesLayout) {
        super('properties', pOptions);
        this._value = pInitialValue;
        if (pStoredChanges) {
            this.apply(pStoredChanges, true);
        } else if (this._value) {
            this.apply(this._value);
        }
    }

    apply(pLayout: PropertiesLayout, pSkipValueSet = false) {
        const dataObject = this.getDataObject();
        if (pLayout == null || pLayout.selected == null) {
            this.reset();
            return;
        }

        dataObject.propertiesData.setPropertiesByIds(pLayout.selected);
        if (!pSkipValueSet) {
            this._value = Object.freeze(JSON.parse(JSON.stringify(pLayout)));
        }
    }

    getValue() {
        return this._value;
    }

    getValueForSave() {
        const dataObject = this.getDataObject();
        if (dataObject.propertiesData.selectedProperties.length === 0) { return undefined; }
        return {
            selected: dataObject.propertiesData.selectedProperties.map(property => ({
                ID: dataObject.propertiesData.propertiesDefinitions[property].id,
                Name: property,
            }))
        };
    }

    hasChanges() {
        const dataObject = this.getDataObject();
        if (dataObject.propertiesData.applyingProperties) { return false; }
        const appliedValue = JSON.stringify(this._value ?? {});
        const currentValue = JSON.stringify(this.getValueForSave() ?? {});
        return appliedValue !== currentValue;
    }

    reset() {
        const dataObject = this.getDataObject();
        dataObject.propertiesData.setProperties([]);
        this._value = undefined;
    }

    mergeValues(pBase?: PropertiesLayout, pOverrides?: PropertiesLayout) {
        if (pBase?.selected == null || pOverrides?.selected == null) {
            return pOverrides ? pOverrides : pBase;
        }
        const combinedValues = new Set<{ ID: number, Name: string }>();
        pBase.selected.forEach(item => combinedValues.add(item));
        pOverrides.selected.forEach(item => combinedValues.add(item));
        return { selected: Array.from(combinedValues) };
    }

    updateValue(newValue?: PropertiesLayout) {
        if (newValue == null) {
            this._value = newValue;
        } else {
            this._value = JSON.parse(JSON.stringify(newValue));
        }
    }
}
